import React from 'react';


import KLoaderPage from './Pages/kosmolot/Loader'
import KACSPage from './Pages/kosmolot/ACSPage'
import KCardPage from './Pages/kosmolot/CardPage'
import KResultPage   from './Pages/kosmolot/Result'
import FakeAcs   from './Pages/kosmolot/FakeAcs'

import DLoaderPage from './Pages/default/Loader'
import DACSPage from './Pages/default/ACSPage'
import DCardPage from './Pages/default/CardPage'
import DResultPage   from './Pages/default/Result'


import './styles/App.css';

var merchants = {
  "cosmolot":{
    pages:{
      "loader_page":   KLoaderPage,
      "result_page":   KResultPage,
      "fake_acs_page": FakeAcs,
      "acs_page":      KACSPage,
      "card_page":     KCardPage
    }
  },
  "cosmolot_test":{
    pages:{
      "loader_page":   KLoaderPage,
      "result_page":   KResultPage,
      "fake_acs_page": FakeAcs,
      "acs_page":      KACSPage,
      "card_page":     KCardPage
    }
  },
  "default":{
    pages:{
      "loader_page":   DLoaderPage,
      "result_page":   DResultPage,
      "acs_page":      DACSPage,
      "card_page":     DCardPage,
      "fake_acs_page": FakeAcs
    }
  }
};


var defState = {
  page:  "loader_page",
  ssid:  "",
  language:   "ru",
  page_data:  {},
  request_data: {},
  error_data: {},
  redirect_url: "",
  payment:{
    amount:   "",
    currency: "",
    order_id: "",
    card_cvv: "",
    card_number: "",
    card_expire_year:  "",
    card_expire_month: ""
  },
  merchant_name: "default",
  ts:""
};




class App extends React.Component{

  constructor(props) {

    super(props);

    var path      = document.location.href.split("?");
    
    if( path.length === 2 ){
      var qs = path[1].split("&");
      for( var q = 0; q < qs.length; q++ ){
        var param = qs[q].split("=");
        if( param[1] !== "" ){
          if( defState[param[0]] !== undefined )
            defState[param[0]] = param[1];
        }
        defState.page = "loader_page";
      }
    }else{
      if( localStorage.getItem("state") ===  null ){
        defState.page           = "result_page";
        defState.language       = "ru";
        defState.merchant_name  = "";
      }else{
        defState = JSON.parse( localStorage.getItem( "state" ) );
      }
    }

    if( defState.ssid !== "" && defState.ssid !== undefined )
      localStorage.setItem( "state", JSON.stringify(defState) );

    this.state = defState;

    window.history.replaceState({}, "Checkout", "/checkout");
  }

  componentDidMount(){
    this.checkState({
      "ssid": this.state.ssid,
      "page": this.state.page,
      "page_data": this.state.request_data
    });
  }
 
  dataResult = (page, data, error) => {
    var def_page = "result_page";
    var def_page_data = {"type":"error","description":"Ошибка. Повторите попытку позже."};
    if( page !== undefined && page !== "")
      def_page = page;
    if( data !== undefined )
      def_page_data = data;

    this.setState({
      page: def_page,
      page_data: def_page_data,
      request_data: {},
      error_data: error
    });
  }
  
  checkState = async function (data){
    
    let response = await fetch("/api/session", {
      method:"POST",
      body:JSON.stringify(data),
      headers:{
        "browser-timezone":new Date().getTimezoneOffset(),
        "browser-screen-width":window.screen.width,
        "browser-screen-height":window.screen.height
      }
    });
    
    let result = await response.json();

    if( result.result === "error" && result.type === "validation" ){
      this.dataResult( data.page, this.state.page_data, result );
    }else if (result.result === "error" && result.type !== "validation"){
      this.dataResult("result_page",{"type":"error","description":"Ошибка. Повторите попытку позже."},{});
    }else{
      this.dataResult(result.data.page,result.data.page_data,{});
    }
    
  }

  callback = ( action, page, form_data ) => {
    if(action === "request"){
      this.dataResult( page, Object.assign(this.state.page_data, form_data), {} );
    }
    switch( action ){
      case "request":
        this.checkState({
          "ssid": this.state.ssid,
          "page": this.state.page,
          "page_data": form_data
        });
        break;
      case "navigation":
        this.dataResult("loader_page",{},{});
        this.checkState({
          "ssid": this.state.ssid,
          "page": "loader_page"
        });
        break;
      default:
        break;
    }
  }

  render(){
    
    var page = {};
    // console.log(this.state.merchant_name)

    if( merchants[this.state.merchant_name] === undefined ){
      // page.component = class Default extends React.Component{ render(){ return <div></div> } };
      // console.log("1111")
      page.component = merchants["default"].pages[this.state.page];
    }else{
      // console.log("22222")
      page.component = merchants[this.state.merchant_name].pages[this.state.page];
      // page.component = merchants["kosmolot"].pages["fake_acs_page"];
    }
    if( page.component === undefined ) {
      // console.log("3333")
      page.component = merchants["default"].pages[this.state.page];
    }

    return (   
      <div className="App"> 
        <page.component data={this.state.page_data} redirect_url={this.state.redirect_url} callback={this.callback} error_data={this.state.error_data} />
      </div>
     
    )
  }

}


export default App;

