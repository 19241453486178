import React from 'react';

import './../../styles/App.css';

class ResultPage extends React.Component{

  goBack = () => {
    if(this.props.redirect_url !== undefined ){
      document.location.href = this.props.redirect_url;
      // this.props.callback("navigation","card_page",{});
    }else{
      this.props.callback("navigation","card_page",{});
    }
  }


  Back = () => {
    this.props.callback("card_page",{});
  }

  render(){
    return(
      <div className="Page kosmolot">
        <div className="Content kosmolot">
          <div className="Content-Form kosmolot">
            <div className={this.props.data.type + " Result-Block-Icon kosmolot"} ></div>
            <div className="Result-Block-Text kosmolot">{this.props.data.description}</div>
            <div className="Button kosmolot" onClick={this.goBack}>
              Назад к магазину
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default ResultPage;
