import React from 'react';
import './../../styles/App.css';

import './CardPage.css';

class CardPage extends React.Component{

  constructor(props){
    super(props);   
  }

  componentDidMount(){
    
    if( this.props.data.card_number !== undefined ){
      document.getElementById("card_number").value = this.props.data.card_number;
      document.getElementById("card_number").parentNode.classList.add("OnFocus");
      document.getElementById("card_number").parentNode.getElementsByClassName("Text-Field-Title")[0].classList.add("OnFocus");
      document.getElementById("card_number").classList.add("onChange");
      if(this.props.error_data !== undefined ){
        for(var k in this.props.error_data.data.keys){
          if( this.props.error_data.data.keys[k] === "card_number" ){
            document.getElementById("card_number").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
          }else{
            document.getElementById("card_number").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
          }
        }
      }
    }
    if( this.props.data.card_expire_year !== undefined ){
      document.getElementById("card_expire_year").value = this.props.data.card_expire_year;
      document.getElementById("card_expire_year").parentNode.classList.add("OnFocus");
      document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Title")[0].classList.add("OnFocus");
      document.getElementById("card_expire_year").classList.add("onChange");
      if(this.props.error_data !== undefined ){
        for(var k in this.props.error_data.data.keys){
          if( this.props.error_data.data.keys[k] === "card_expire_year" ){
            document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
          }else{
            document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");  
          }
        }
      }
    }
    if( this.props.data.card_expire_month !== undefined ){
      document.getElementById("card_expire_month").value = this.props.data.card_expire_month;
      document.getElementById("card_expire_month").parentNode.classList.add("OnFocus");
      document.getElementById("card_expire_month").parentNode.getElementsByClassName("Text-Field-Title")[0].classList.add("OnFocus");
      document.getElementById("card_expire_month").classList.add("onChange");
      if(this.props.error_data !== undefined ){
        for(var k in this.props.error_data.data.keys){
          if( this.props.error_data.data.keys[k] === "card_expire_month" ){
            document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
          }else{
            document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");  
          }
        }
      }
    }
  }

  CardValid = (card_number) => {
    var summ = 0;
    var result = false;
    if(card_number.length === 16){
      for(var i=0; i<card_number.length; i++){
        if(i%2 === 0) {
          if(parseInt(card_number[i])*2>=10) {
            summ += parseInt(card_number[i])*2 - 9;
          } else {
            summ += parseInt(card_number[i])*2;
          }
        } else {
          summ += parseInt(card_number[i]);
        }
      }
      if(summ%10 === 0)
        result = true 
    }
    return result;
  }

  Click = (e) => {

    var errors = 0;
    var inputs = document.getElementsByName("form");
    var data = {};
    for( var i = 0; i < inputs.length; i++ ){
      var value  = inputs[i].value;
      var regexp = inputs[i].getAttribute("regexp");
      var id = inputs[i].getAttribute("id");
      if( value !== undefined ){
        if( value.match(regexp) === null ){
          if( id !== "card_expire_month"){
            inputs[i].parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
            errors++;
          }else{
            document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
            errors++;
          }
        }else{
          if( id !== "card_expire_month"){
            inputs[i].parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
            data[id] = value;
          }else{
            data[id] = value;
          }
        }
      }
    }
    
    if(!this.CardValid(document.getElementById("card_number").value))
      errors++

    if(errors === 0){
      this.props.callback("request","loader_page",data); 
    }

  }

  Change = (e) => {
    if( e.target.value !== "" ){
      e.target.classList.add("onChange");
    }else{
      e.target.classList.remove("onChange");
    }
    var input = e.target;
    var value  = input.value;
    var regexp = input.getAttribute("regexp");
    var id = input.getAttribute("id");
    if( value.match(regexp) === null ){
      if( id === "card_expire_year"){
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
        var mval = document.getElementById("card_expire_month").value;
        var mreg = document.getElementById("card_expire_month").getAttribute("regexp");
        if( mval.match(mreg) === null ){
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
        }
      }else if( id !== "card_expire_month"){
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
      }else{
        document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
        document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
      }
    }else{
      if(id === "card_expire_year" ){
        document.getElementById("card_cvv").focus();
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("error");
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
        var mval = document.getElementById("card_expire_month").value;
        var mreg = document.getElementById("card_expire_month").getAttribute("regexp");
        if( mval.match(mreg) === null ){
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
        }else{
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("error");
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
        } 
      }else if( id !== "card_expire_month"){
        if(id === "card_number"){
          document.getElementById("card_expire_month").focus();
        } else if (id === "card_cvv") {
          document.getElementById("submit_data").childNodes[0].classList.add("onFocus");
        }
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("error");
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
      }else{
        if(id === "card_expire_month"){
          document.getElementById("card_expire_year").focus();
        }
        document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("error");
        document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
        var yval = document.getElementById("card_expire_year").value;
        var yreg = document.getElementById("card_expire_year").getAttribute("regexp");
        if( yval.match(yreg) === null ){
          document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
          document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
        }else{
          document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("error");
          document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
        } 
      }
    }
    if(id === "card_number") {
      if(!this.CardValid(e.target.value))      
        document.getElementById("card_number").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
    }
  }

  Focus = (e) => {
    e.target.parentNode.classList.add("OnFocus");
    e.target.parentNode.getElementsByClassName("Text-Field-Title")[0].classList.add("OnFocus");
  }

  Blur = (e) => {
    if( e.target.value === "" ){
      e.target.parentNode.classList.remove("OnFocus");
      e.target.parentNode.getElementsByClassName("Text-Field-Title")[0].classList.remove("OnFocus");
    }
  }

  handleKeyPress = (e) => {
    
    if(e.key === "Enter") {
      if(document.getElementById("submit_data").childNodes[0].classList.contains("onFocus")){
        this.Click()
      }
    }
    document.getElementById("submit_data").childNodes[0].classList.remove("onFocus")
  }

  disableFocus = (e) => {
    document.getElementById("submit_data").childNodes[0].classList.remove("onFocus")
  }


  render() {
    var amount = "";
    var currency = "";
    if( this.props.data.payment !== undefined ){
      if(this.props.data.payment.original_amount !== "" )
        amount = this.props.data.payment.original_amount;
      else
        amount = this.props.data.payment.amount;
      if(this.props.data.payment.original_currency !== "" )
        currency = this.props.data.payment.original_currency;
      else
        currency = this.props.data.payment.currency;
    }  
      
    return (
      <div className="Wrapper" onKeyDown={this.handleKeyPress} onClick={this.disableFocus}>
        {/* {this.props.loader ?  */}
          <div className="Page default">
            <div className="Content default">

              <div className="Content-Form default top">
              <div className="Label default">
                  <div className="Label-Title default top small">Order ID:</div>
                  {/* <div className="Label-Text default">{this.props.data.payment.order_id}</div> */}
                  <div className="Label-Text default">{this.props.data.payment.order_id}</div>
                </div>
                <div className="Label default mid">
                  <div className="Label-Title default mid">Cумма к оплате:</div>
                  <div className="Label-Text default right big">{amount}</div>
                  <div className="Label-Text default currency right big">{currency}</div>
                </div>
              </div>

              
              <div className="Content-Form default white" >
                <div className="TextField default card">
                  <div className="Text-Field-Title default title">Номер карты</div>
                  <input name="form" onChange={this.Change} id="card_number" regexp={"5[0-9]{15,15}|4[0-9]{15,15}|6[0-9]{15,15}"} placeholder="4XXXXXXXXXXXXX59" maxlength="16" type="text" autoComplete="cc-number" className="TextField-Text default"/>
                  <div className="Text-Field-Validation-Icon default"></div>
                </div>
                <div className="TextField default little">
                  <div className="Text-Field-Title default title">Месяц</div>
                  <input onChange={this.Change} name="form" id="card_expire_month" regexp={"01|02|03|04|05|06|07|08|09|10|11|12"} placeholder="MM" maxlength="2" type="text" autoComplete="cc-exp-month" className="TextField-Text default"/>
                  <div className="Text-Field-Validation-Icon default"></div>
                </div>
                <div className="TextField default little">
                  <div className="Text-Field-Title default title">Год</div>
                  <input name="form" onChange={this.Change} id="card_expire_year"  regexp={"[0-9]{2,2}"} placeholder="ГГ" maxlength="2" type="text" autoComplete="cc-exp-year" className="TextField-Text default"/>
                  <div className="Text-Field-Validation-Icon default "></div>
                </div>
                <div className="TextField default small right">
                  <div className="Text-Field-Title default title">CVV2</div>
                  <input name="form" onChange={this.Change} id="card_cvv" regexp={"[0-9]{3,3}"} placeholder="012" maxlength="3" placeholder="•••" type="password" autoComplete="cc-csc" className="TextField-Text default"/>
                  <div className="Text-Field-Validation-Icon default"></div>
                </div>
              </div>
              
              <div className="Text center default">
                Платеж защищен сертификатом безопасности SSL и протоколом 3D Secure 
              </div>
              <div id="submit_data" onBlur={this.Blur}>
                <div className="Button default"  onClick={this.Click}>
                  Оплатить
                </div>
              </div>

            </div>
            
          </div>
      </div>     
      )
    }
  }

export default CardPage;
