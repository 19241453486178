import React from 'react';
import './../../styles/App.css';
// import CircularProgress from '@material-ui/core/CircularProgress';

import "./Loader.css";

class Loader extends React.Component{

  render(){
    return(
      <div className="Wrapper">

        <div className="Page default loader">
          <div className={ "loader-wrapper default" }>
              <div className={ "loader default" }>
                <div className={ "loader-ring default" }>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>              
          </div>
      </div>
    )
  }

}

export default Loader;
