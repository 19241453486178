import React from 'react';
import './../../styles/App.css';


class CardPage extends React.Component{

  constructor(props){
    super(props);   
  }

  componentDidMount(){
    
    if( this.props.data.card_number !== undefined ){
      document.getElementById("card_number").value = this.props.data.card_number;
      document.getElementById("card_number").parentNode.classList.add("OnFocus");
      document.getElementById("card_number").parentNode.getElementsByClassName("Text-Field-Title")[0].classList.add("OnFocus");
      document.getElementById("card_number").classList.add("onChange");
      if(this.props.error_data !== undefined ){
        for(var k in this.props.error_data.data.keys){
          if( this.props.error_data.data.keys[k] === "card_number" ){
            document.getElementById("card_number").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
          }else{
            document.getElementById("card_number").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
          }
        }
      }
    }
    if( this.props.data.card_expire_year !== undefined ){
      document.getElementById("card_expire_year").value = this.props.data.card_expire_year;
      document.getElementById("card_expire_year").parentNode.classList.add("OnFocus");
      document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Title")[0].classList.add("OnFocus");
      document.getElementById("card_expire_year").classList.add("onChange");
      if(this.props.error_data !== undefined ){
        for(var k in this.props.error_data.data.keys){
          if( this.props.error_data.data.keys[k] === "card_expire_year" ){
            document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
          }else{
            document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");  
          }
        }
      }
    }
    if( this.props.data.card_expire_month !== undefined ){
      document.getElementById("card_expire_month").value = this.props.data.card_expire_month;
      document.getElementById("card_expire_month").parentNode.classList.add("OnFocus");
      document.getElementById("card_expire_month").parentNode.getElementsByClassName("Text-Field-Title")[0].classList.add("OnFocus");
      document.getElementById("card_expire_month").classList.add("onChange");
      if(this.props.error_data !== undefined ){
        for(var k in this.props.error_data.data.keys){
          if( this.props.error_data.data.keys[k] === "card_expire_month" ){
            document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
          }else{
            document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");  
          }
        }
      }
    }
  }

  Click = (e) => {

    var errors = 0;
    var inputs = document.getElementsByName("form");
    var data = {};
    for( var i = 0; i < inputs.length; i++ ){
      var value  = inputs[i].value;
      var regexp = inputs[i].getAttribute("regexp");
      var id = inputs[i].getAttribute("id");
      if( value !== undefined ){
        if( value.match(regexp) === null ){
          if( id !== "card_expire_month"){
            inputs[i].parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
            errors++;
          }else{
            document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
            errors++;
          }
        }else{
          if( id !== "card_expire_month"){
            inputs[i].parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
            data[id] = value;
          }else{
            data[id] = value;
          }
        }
      }
    }

    if(errors === 0){
      this.props.callback("request","loader_page",data); 
    }

  }

  Change = (e) => {
    if( e.target.value !== "" ){
      e.target.classList.add("onChange");
    }else{
      e.target.classList.remove("onChange");
    }
    var input = e.target;
    var value  = input.value;
    var regexp = input.getAttribute("regexp");
    var id = input.getAttribute("id");
    if( value.match(regexp) === null ){
      if( id === "card_expire_year"){
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
        var mval = document.getElementById("card_expire_month").value;
        var mreg = document.getElementById("card_expire_month").getAttribute("regexp");
        if( mval.match(mreg) === null ){
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
        }
      }else if( id !== "card_expire_month"){
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
      }else{
        document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
        document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
      }
    }else{
      if(id === "card_expire_year" ){
        document.getElementById("card_cvv").focus();
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("error");
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
        var mval = document.getElementById("card_expire_month").value;
        var mreg = document.getElementById("card_expire_month").getAttribute("regexp");
        if( mval.match(mreg) === null ){
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
        }else{
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("error");
          input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
        } 
      }else if( id !== "card_expire_month"){
        if(id === "card_number"){
          document.getElementById("card_expire_month").focus();
        } else if (id === "card_cvv") {
          document.getElementById("submit_data").childNodes[0].classList.add("onFocus");
        }
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("error");
        input.parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
      }else{
        if(id === "card_expire_month"){
          document.getElementById("card_expire_year").focus();
        }
        document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("error");
        document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
        var yval = document.getElementById("card_expire_year").value;
        var yreg = document.getElementById("card_expire_year").getAttribute("regexp");
        if( yval.match(yreg) === null ){
          document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("ok");
          document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("error");
        }else{
          document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.remove("error");
          document.getElementById("card_expire_year").parentNode.getElementsByClassName("Text-Field-Validation-Icon")[0].classList.add("ok");
        } 
      }
    }
  }

  Focus = (e) => {
    e.target.parentNode.classList.add("OnFocus");
    e.target.parentNode.getElementsByClassName("Text-Field-Title")[0].classList.add("OnFocus");
  }

  Blur = (e) => {
    if( e.target.value === "" ){
      e.target.parentNode.classList.remove("OnFocus");
      e.target.parentNode.getElementsByClassName("Text-Field-Title")[0].classList.remove("OnFocus");
    }
  }

  handleKeyPress = (e) => {
    
    if(e.key === "Enter") {
      if(document.getElementById("submit_data").childNodes[0].classList.contains("onFocus")){
        this.Click()
      }
    }
    document.getElementById("submit_data").childNodes[0].classList.remove("onFocus")
  }

  disableFocus = (e) => {
    document.getElementById("submit_data").childNodes[0].classList.remove("onFocus")
  }

  Redirect = (e) => {
    if(this.props.redirect_url !== undefined ){
      document.location.href = this.props.redirect_url;
    }
  }

  render() {
    var amount = "";
    var currency = "";
    if(this.props.data.payment.original_amount !== "" )
      amount = this.props.data.payment.original_amount;
    else
      amount = this.props.data.payment.amount;
    if(this.props.data.payment.original_currency !== "" )
      currency = this.props.data.payment.original_currency;
    else
      currency = this.props.data.payment.currency;  
      
    return (
      <div className="Wrapper" onKeyDown={this.handleKeyPress} onClick={this.disableFocus}>
        {/* {this.props.loader ?  */}
          <div className="Page kosmolot">
            <div className="Content kosmolot">
              <div className="Content-Form kosmolot">
                <div className="Label kosmolot">
                  <div className="Label-Title kosmolot">К оплате:</div>
                  <div className="Label-Text kosmolot right gold">{amount}</div>
                  <div className="Label-Text currency right kosmolot gold">{currency}</div>
                </div>
                <div className="Label kosmolot">
                  <div className="Label-Title kosmolot">OrderID:</div>
                  <div className="Label-Text kosmolot">{this.props.data.payment.order_id}</div>
                </div>
              </div>
              <div className="Content-Form nobg kosmolot">
                
                  <svg viewBox="0 0 36 36"><g fill-rule="evenodd"><path fill-rule="nonzero" d="M28.868 8c1.679 0 3.131 1.372 3.132 2.957V25.16c0 1.586-1.445 2.84-3.136 2.84H7.132C5.452 28 4 26.628 4 25.043V10.957C4 9.372 5.452 8 7.132 8h21.736zM7.132 9.859c-.592 0-1.163.54-1.163 1.098v4.419h24.062v-4.419c0-.559-.571-1.098-1.163-1.098H7.132zm21.73 16.283c.64 0 1.168-.458 1.168-.981v-7.925H5.97v7.808c0 .559.57 1.098 1.162 1.098h21.73zm-18.7-5.158h6.192v1.859H10.16v-1.86z"></path></g></svg>
              
                <div className="Text kosmolot">Карта</div>
              </div>
              <div className="Content-Form kosmolot" >
                <div className="TextField kosmolot">
                  <div className="Text-Field-Title kosmolot title">Номер карты</div>
                  <input name="form" onChange={this.Change} id="card_number" autoFocus={true} regexp={"5[0-9]{15,15}|4[0-9]{15,15}|6[0-9]{15,15}"} placeholder="4XXXXXXXXXXXXX59" maxlength="16" type="text" className="TextField-Text kosmolot"/>
                  <div className="Text-Field-Validation-Icon kosmolot"></div>
                </div>
                <div className="TextField kosmolot little">
                  <div className="Text-Field-Title kosmolot title">Месяц</div>
                  <input onChange={this.Change} name="form" id="card_expire_month" regexp={"01|02|03|04|05|06|07|08|09|10|11|12"} placeholder="MM" maxlength="2"   type="text" className="TextField-Text kosmolot"/>
                  <div className="Text-Field-Validation-Icon kosmolot"></div>
                </div>
                <div className="TextField kosmolot little">
                  <div className="Text-Field-Title kosmolot title">Год</div>
                  <input name="form" onChange={this.Change} id="card_expire_year"  regexp={"[0-9]{2,2}"} placeholder="ГГ" maxlength="2"   type="text" className="TextField-Text kosmolot"/>
                  <div className="Text-Field-Validation-Icon kosmolot "></div>
                </div>
                <div className="BluePipe kosmolot"></div>
                <div className="TextField kosmolot small right">
                  <div className="Text-Field-Title kosmolot title">CVV2</div>
                  <input name="form" onChange={this.Change} id="card_cvv" regexp={"[0-9]{3,3}"} placeholder="012" maxlength="3" placeholder="•••" type="password" className="TextField-Text kosmolot"/>
                  <div className="Text-Field-Validation-Icon kosmolot"></div>
                </div>
                <div className="Text center bg">
                  Платеж защищен сертификатом безопасности SSL и протоколом 3D Secure 
                </div>
                <div id="submit_data" onBlur={this.Blur}>
                  <div className="Button kosmolot"  onClick={this.Click}>
                    Оплатить
                  </div>
                </div>
                <div className="Button-Back" onClick={this.Redirect}>
                  <div className="Back-Arrow-Icon"/>
                  <div className="Text-Field-Button kosmolot">Назад</div>
                </div>
              </div>
            </div>
          </div>
      </div>     
      )
    }
  }

export default CardPage;
