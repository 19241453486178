import React from 'react';
import './../../styles/App.css';
import CircularProgress from '@material-ui/core/CircularProgress';

class Loader extends React.Component{

  render(){
    return(
      <div className="Wrapper">

        <div className="Page kosmolot">
          <div className={ "loader-wrapper kosmolot" }>
              <div className={ "loader kosmolot" }>
                <div className={ "loader-ring kosmolot" }>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>              
          </div>
      </div>
    )
  }

}

export default Loader;
