import React from 'react';
import './../../styles/App.css';

class FakeAcs extends React.Component{

  Click = () => {
    this.props.callback("navigation");
  }

  render(){
  
    return(
      <div className="Wrapper">
        <div className="Fake-Form">
          <div className="Fake-Logo-Left"></div>
          <div className="Fake-Logo-Right"></div>
          <div className="Fake-Top-SLabel">Authenticate using one-time password (OTP)</div>
          <div className="Fake-Top-Label"> Your one-time password (OTP) has been sent to your mobile phone.</div>
          <div className="Fake-Row">
            <div  className="Fake-title">Merchant: </div>
            <div  className="Fake-value">ТОВ "Бухгалтерська ВККА"</div>
          </div>
          <div className="Fake-Row">
            <div  className="Fake-title">Amount: </div>
            <div  className="Fake-value">UAH 0.10</div>
          </div>
          <div className="Fake-Row">
            <div  className="Fake-title">Date:</div>
            <div  className="Fake-value">20201126 14:32:24</div>
          </div>
          <div className="Fake-Row">
            <div  className="Fake-title">Card Number: </div>
            <div  className="Fake-value">XXXX XXXX XXXX 9729</div>
          </div>
          <div className="Fake-Row">
            <div  className="Fake-title">Personal Message: </div>
          </div>
          <div className="Fake-Row">
            <div  className="Fake-title">One-time password (OTP): </div>
            <input type="text" className="Fake-otp"/>
          </div>
          <div className="Fake-Row">
            <div  className="Fake-link">Help</div>
            <div  className="Fake-link">Request new OTP</div>
            <div  className="Fake-link">Cancel</div>
            <div  id="test" onClick={this.Click}>Submit</div>
          </div>
        </div>
      </div>
    )
  }

}

export default FakeAcs;
