import React from 'react';
import './../../styles/App.css';

class ACSPage extends React.Component{

  componentDidMount(){
    if(this.props.data.acs_form !== undefined) {
      document.getElementById("submit").click();
    } else {
      document.getElementById("creq_form").submit();
    }
  }

  render(){
    if(this.props.data.acs_form !== undefined) {
      return(
        <form id="downloadForm" action={this.props.data.acs_form.action} method={this.props.data.acs_form.method} hidden >
          {this.props.data.acs_form.inputs.map( (i,k) => {
            return <input id={k} type="hidden" name={i.name} value={i.value} />
          })}
          <input id="submit" type="submit" value="Submit"  />
        </form>
      )
    } else {
      return React.createElement("html", {dangerouslySetInnerHTML: {__html: this.props.data.acs_html}})
    }
  }

}

export default ACSPage;
