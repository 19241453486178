import React from 'react';

import './Result.css';

class ResultPage extends React.Component{

  goBack = () => {
    if(this.props.redirect_url !== undefined ){
      document.location.href = this.props.redirect_url;
      // this.props.callback("navigation","card_page",{});
    }else{
      this.props.callback("navigation","card_page",{});
    }
  }


  Back = () => {
    this.props.callback("card_page",{});
  }

  render(){
    return(
      <div className="Page default">
        <div className="Content default">
          <div className="Content-Form default white result">
            <div className={this.props.data.type + " Result-Block-Icon default"} ></div>
            <div className="Result-Block-Text default">{this.props.data.description}</div>
            <div className="Button default result" onClick={this.goBack}>
              Назад к магазину
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default ResultPage;
