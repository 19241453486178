import React from 'react';
import './../../styles/App.css';

class ACSPage extends React.Component{

  componentDidMount(){
    document.getElementById("submit").click();
  }

  render(){
    return(
      <form id="downloadForm" action={this.props.data.acs_form.action} method={this.props.data.acs_form.method} hidden >
        {this.props.data.acs_form.inputs.map( (i,k) => {
          return <input id={k} type="hidden" name={i.name} value={i.value} />
        })}
        <input id="submit" type="submit" value="Submit"  />
      </form>
    )
  }

}

export default ACSPage;
